@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-size: 62.5%; /* 10px/16px*100% = 62.5% */
}

details > summary::-webkit-details-marker {
  display: none;
}

.marquee:hover .marquee-content {
	@media screen and (min-width: 1024px) {
		animation-play-state: paused;
	}
}

.marquee-content {
	animation: marquee 50s linear infinite;
}

@keyframes marquee {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-100% - 8rem));
	}
}
